import React, { useContext } from "react"
import { ThemeContext } from "../../contexts/ThemeContext"

import { H1, H3, buttonBckg, StarsSix, Row } from "../../globalStyles"
import {
  FullContainerLocal,
  ContactDiv,
  Planets,
  ColMin,
  SatImage,
  Satellite,
  Radio,
} from "./ContactStyle"
// import { graphql, useStaticQuery } from "gatsby"
import ContactForm from "../HomeSuccessSection/ContactForm"
import Footer from "../HomeSuccessSection/CustomFooter"

function Contact({ data }) {
  const { isDarkMode } = useContext(ThemeContext)

  let items = data.allContentfulContactMain.nodes[0]

  const {
    contactButtonText,
    contactEmailPlaceholder,
    contactNamePlaceholder,
    contactNameMessage,
    // buttonText,
    textPlace,
    mailPlace,
    nevPlace,
    webmindEmail,
    webmindTel,
    adatkezeles,
  } = data.allContentfulSuccessPage.nodes[0]

  let { para, svg, title } = items
  // console.log(svg)
  return (
    <FullContainerLocal dark={isDarkMode} className="container-fluid">
      <Planets
        dark={isDarkMode}
        neptune
        alt={svg[0].title}
        src={svg[0].file.url}
      />
      <Planets saturn alt={svg[1].title} src={svg[1].file.url} />

      <Satellite>
        <SatImage black alt={svg[2].title} src={svg[2].file.url} />
        <Radio />
      </Satellite>
      {!isDarkMode && (
        <>
          <StarsSix /> <StarsSix bottom />
        </>
      )}
      <Row>
        <div className="row justify-content-center   mr-auto text-center align-items-center">
          <ColMin
            small
            top
            className="col-11 col-md-8 col-lg-7   align-self-center"
          >
            <H1
              // landing
              style={{
                color: isDarkMode ? buttonBckg.darkHover : "white",
              }}
            >
              {title}
            </H1>
            <H3
              className="mt-5"
              style={{ color: isDarkMode ? "#505050" : "white" }}
            >
              {para.para}
            </H3>
          </ColMin>
        </div>
        <div
          style={{
            borderRadius: "15px",
          }}
          className="row  justify-content-center mr-auto ml-auto  text-center align-items-top align-self-center"
        >
          <ContactDiv dark={isDarkMode}>
            <ContactForm
              landing={false}
              dark={isDarkMode}
              button={contactButtonText}
              message={contactNameMessage}
              email={contactEmailPlaceholder}
              name={contactNamePlaceholder}
              className=" m-auto"
              webmindTel={webmindTel}
              webmindEmail={webmindEmail}
              textPlace={textPlace}
              mailPlace={mailPlace}
              nevPlace={nevPlace}
              adatkezeles={adatkezeles}
            />
          </ContactDiv>
        </div>

        <Footer footer={"true"} dark={isDarkMode} />
      </Row>
    </FullContainerLocal>
  )
}

export default Contact
