import styled from "styled-components"
import { FullContainer, buttonBckg } from "../../globalStyles"

export const FullContainerLocal = styled(FullContainer)`
  height: auto;

  position: relative;

  ${({ dark }) =>
    dark
      ? `

  background:rgba(240,240,250);
  
  
  `
      : ` 
  
 background: rgba(24,43,80,1);

  `}

  color: #505050 !important;

  @media (max-width: 640px) {
    padding-bottom: 15px;
    min-height: 640px;
  }

  @media (min-width: 641px) {
    min-height: 800px;
  }

  @media (min-width: 1024px) {
    overflow: hidden;
  }

  ${FullContainer};
  overflow: hidden;
`
export const ContactDiv = styled.div`
  background: ${({ dark }) => (dark ? `rgba(255,255,255,.4)` : `transparent`)};
  padding-bottom: 2.5%;
  margin-bottom: 6%;
  border-radius: 15px;
  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) {
    width: 100%;
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    width: 100%;
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
    width: 50%;
  }
`

export const Planets = styled.img`
  display: block;
  position: absolute;
  z-index: 0;
  ${({ neptune }) =>
    neptune &&
    `
  @media (max-width: 640px) {
      opacity:0.8;
  filter: grayscale(0.4);
 bottom:-20px;
left:-10px;
  width:100px;

}

@media (min-width: 641px) {
    width: 270px;
  opacity:0.8;
  filter: grayscale(0.4);
bottom:120px;
left:-60px;

}

  animation: qwe 1s cubic-bezier(.2,0,0,0);
  @keyframes qwe {
    0% {
  transform: translate(-60px, -30px);
    }

    100% {
      transform: translate(0, 0);
    }
  } ;



  
  `}

  ${({ saturn }) =>
    saturn &&
    `
  @media (max-width: 640px) {
      width:100px;
top:0;
right:-30px;
          opacity:0.8;
  filter: grayscale(.4);
}

@media (min-width: 641px) {
    width: 250px;
  right:0;
    opacity:0.8;
  filter: grayscale(.4);
top:0;
right:-80px;

}

  animation: sd 1s cubic-bezier(.3, 0, .5, 1);
  @keyframes sd {
    0% {
  transform: translate(60px, -50px) rotate(-50deg);
    }

    100% {
      transform: translate(0, 0) ;
    }
  } ;

  
  `}
`
export const ColMin = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 640px) {
    margin: 5% 0 0 0;

    ${({ top }) => top && `margin-bottom:5%; margin-top:10%;`}
  }

  @media (min-width: 641px) {
    ${({ top }) => top && `margin-top:10%;`}
    margin-bottom:25px;
  }

  @media (min-width: 1024px) {
    ${({ top }) => top && `margin-top:3%; margin-bottom:3%;`}
    ${({ top }) => !top && `margin-top:2%; `}
    margin-bottom: 5%;
  }
`

export const Satellite = styled.div`
  display: block;
  /* background: green; */
  width: 100px;
  height: 100px;
  position: absolute;

  @media (max-width: 640px) {
    left: 0px;
    top: 20px;
    width: 80px;
    height: 80px;
  }

  @media (min-width: 641px) {
    left: 38%;
    top: 2%;
    width: 100px;
    height: 100px;
  }

  @media (min-width: 1024px) {
    left: 200px;
    top: 20px;
    width: 100px;
    height: 100px;
  }

  animation: sati 120s linear infinite;

  @keyframes sati {
    0% {
      transform: rotate(0);
    }

    50% {
      transform: rotate(360deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
`

export const SatImage = styled.img`
  width: 40%;
  position: absolute;
  margin: auto;
  margin-left: auto;
  margin-right: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media (max-width: 640px) {
    width: 30%;
    top: 47%;
    left: 47%;
  }

  @media (min-width: 641px) {
    top: 40%;
    left: 40%;
  }

  @media (min-width: 1024px) {
    top: 40%;
    left: 40%;
  }
`

export const Radio = styled.div`
  border: 1px solid ${buttonBckg.dark};
  border-radius: 50%;
  background-color: ${buttonBckg.dark};

  display: block;
  position: absolute;
  top: 30px;
  left: 30px;
  animation: radio 3s ease-out infinite;
  transition: 400ms;
  opacity: 1;

  @keyframes radio {
    0% {
      height: 20%;
      width: 20%;
      opacity: 0;
      position: relative;
    }

    50% {
      opacity: 0.1;
    }

    100% {
      height: 100%;
      width: 100%;
      margin-left: -40%;
      margin-top: -40%;
      opacity: 0;
    }
  }
`
