import React from "react"
import styled from "styled-components"
import { H5, buttonBckg } from "../../globalStyles"
import { Link } from "gatsby"
import useWindowSize from "../../hooks/resizeHook"
import { graphql, useStaticQuery } from "gatsby"

function Footer(props) {
  const { dark, footer, landing } = props
  const [width] = useWindowSize()

  const data = useStaticQuery(graphql`
    {
      allContentfulFooter {
        nodes {
          aszf
          privacy
          copyright
        }
      }
    }
  `)

  const { aszf, privacy, copyright } = data.allContentfulFooter.nodes[0]
  return (
    <div className="row mt-5">
      <CustomFooter
        landing={landing}
        dark={dark}
        right
        className="col-12 col-md-6 col-lg-6 "
      >
        <div className="row align-items-center  my-auto justify-content-center justify-self-center">
          <Col
            landing={"false"}
            dark={dark}
            className="col-6  col-md-6 col-lg-5 "
          >
            <Links rotate={"20deg"} to="/terms" className="text-center">
              <H5 dark={dark} footer={footer}>
                {aszf}
              </H5>
            </Links>
          </Col>
          <Col
            landing={"true"}
            dark={dark}
            className="col-6  col-md-6 col-lg-5 "
          >
            <Links rotate={"-120deg"} to="/privacy" className="text-center">
              <H5 dark={dark} footer={footer}>
                {privacy}
              </H5>
            </Links>
          </Col>
        </div>
        {width < 641 && (
          <div className="row align-items-center vertical-center  justify-content-center justify-self-center">
            <Col
              landing={"true"}
              dark={dark}
              className="col-12 col-md-12 col-lg-12"
            >
              <H5 dark={dark} footer={footer}>
                {/* Copyright © 2020 Webmind */}
                {copyright}
              </H5>
            </Col>
          </div>
        )}
      </CustomFooter>
      {width > 641 && (
        <CustomFooter
          landing={landing}
          dark={dark}
          left
          className="col-6 col-md-6  "
        >
          <div className="row align-items-center vertical-center my-auto   justify-content-center justify-self-center">
            <Col dark={dark} className="col-12 col-md-12 col-lg-12">
              <H5 dark={dark} footer={footer}>
                {/* Copyright © 2020 Webmind | All Rights Reserved */}
                {copyright}
              </H5>
            </Col>
          </div>
        </CustomFooter>
      )}
    </div>
  )
}

export const CustomFooter = styled.div`
  position: absolute;

  ${({ right }) => right && "right: 0;"}
  ${({ left }) => left && "left: 0;"}

/* border:1px solid green; */
  bottom: 2px;
  width: 100%;
  background-color: transparent;
  color: white;
  text-align: center;
  height: auto;

  @media (max-width: 640px) {
    height: auto;
  }
  @media (min-width: 641px) {
    border-bottom: 1px solid
      ${({ dark, landing }) =>
        !dark && !landing ? buttonBckg.light : "transparent"};
    border-top: 1px solid
      ${({ dark, landing }) =>
        !dark && !landing ? buttonBckg.light : "transparent"};
  }
  margin-top: 50px !important;
`

export const Col = styled.div`
  /* border: 1px solid pink; */
  margin-top: auto;
  margin-bottom: auto;
  height: auto;
  display: inline-block;
  position: relative;

  @media (max-width: 640px) {
    border-bottom: 1px solid
      ${({ dark, landing }) => (!dark ? buttonBckg.light : "transparent")};
    margin: 2px 0 2px 0;
  }

  @media (min-width: 641px) {
    margin: 2px 0 2px 0;
  }
`

export const Links = styled(Link)`
  font-weight: 500;
  text-decoration: none;
  ${({ footer, dark }) => footer && `color: ${dark ? "#505050" : "white"}`}

  cursor: pointer;

  @media (max-width: 640px) {
    font-size: 0.65rem;
  }

  @media (min-width: 641px) {
    font-size: 1rem;
  }
`

export default Footer
