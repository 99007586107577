import React from "react"
import Layout from "../components/Layout"
import ContactComponent from "../components/Contact/Contact"
import { graphql, useStaticQuery } from "gatsby"
import HelmetComponent from "../components/Helmet"
import useIsClient from "../hooks/rehydration"
// import Loader from "../components/Loader/Loader"

function About() {
  const { isClient } = useIsClient()

  const data = useStaticQuery(graphql`
    {
      allContentfulSeoEach(
        sort: { fields: createdAt, order: ASC }
        filter: { node_locale: { eq: "hu" } }
      ) {
        edges {
          node {
            id
            title
            url
            description
            facebookImage {
              fixed {
                srcWebp
              }
            }
          }
        }
      }
      allContentfulContactMain(filter: { node_locale: { eq: "hu" } }) {
        nodes {
          para {
            para
          }
          svg {
            title
            file {
              url
            }
          }
          title
        }
      }
      allContentfulSuccessPage(filter: { node_locale: { eq: "hu" } }) {
        nodes {
          buttonText
          contactButtonText
          contactEmailPlaceholder
          contactNameMessage
          contactNamePlaceholder
          textPlace
          mailPlace
          nevPlace
          webmindEmail
          webmindTel
          adatkezeles
        }
      }
    }
  `)
  let seo = data.allContentfulSeoEach.edges[6].node
  if (!isClient) return <HelmetComponent seo={seo} />
  return (
    <Layout>
      <HelmetComponent seo={seo} />
      <ContactComponent data={data} />
    </Layout>
  )
}

export default About
